import React, { FC } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const AboutPage: FC = () => (
  <Layout>
    <SEO title="Um kolefnisreikninn" />
    <p>
      Eftirtaldir starfsmenn Skógræktarinnar tóku þátt í að búa til
      Skógarkolefnisreikninn með því að leggja í púkkið skógmælingagögn,
      vaxtarferla og vinna að úrvinnslu og forritun á reikninum
    </p>
    <p>
      Arnór Snorrason, Björn Traustason, Ólafur St. Arnarsson, Bjarki Þ.
      Kjartansson, Lárus Heiðarsson og Þorbergur H. Jónsson.
    </p>
    <p>
      Þessi fyrsta útgáfa er prufuútgáfa og búast má við að hún taki 
      fljótlega einhverjum breytingum ef og þegar notendur benda á 
      það sem betur má fara og forritunarvillur uppgötvast. Eftir er 
      að skrifa fleiri kafla og er það helst að nefna kafla sem lýsir 
      nánar aðferðafræði, gögnum, gagnavinnslu og heimildum sem nýttar
      eru við gerð reiknisins. Í framtíðinni gerum við ráð fyrir að
      taka inn fleiri þætti s.s. sjálfvirkum reiknir á skjólfari,
      fjölbreyttara landval sem þá stýrir gróskustigi trjátegunda,
      fleiri gróskuflokka fyrir trjátegundir og áhrif grisjana á 
      kolefnisbindingu svo eitthvað sé nefnt. Þannig er það von okkar
      að Skógarkolefnisreiknir eigi eftir að þróast og gera nákvæmari 
      spár um þá skógrækt og ræktunarstaði sem notendur velja eða eru 
      að skoða. 
    </p>
  </Layout>
)

export default AboutPage
